import axios from 'axios';

import Utils from '@/common/utils';

export default {
  search: function (request) {
    return axios.get('/api/procedure/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/procedure/find/${id}`);
  },
  update: function (data) {
    if (data.id > 0) return axios.put(`/api/procedure/${data.id}`, data);
    else return axios.post('/api/procedure', data);
  },
  delete: function (id) {
    return axios.delete(`/api/procedure/${id}`);
  },
  exportExcel: function (request) {
    return Utils.downloadFile('/api/procedure/exportExcel', 'ProcedureExport.xlsx', request);
  },
};
