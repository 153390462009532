<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка процедуры</p>
    </div>
    <div>
      <div class="row">
        <div class="form-group required">
          <ComponentInput
            v-model="card.name"
            label="Название"
            required
            maxLength="255"
            placeholder="Введите название"
          />
        </div>
      </div>

      <div class="filter-trigger" :class="{ active: openAdditional }" @click="openAdditional = !openAdditional">
        <p>Дополнительные поля</p>
      </div>

      <div v-if="openAdditional">
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.stageName" label="Наименование этапа" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.sequence" label="Последовательность" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.previousProcedure" label="Предшествующая процедура" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.nextProcedure" label="Последующая процедура" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.relatedOperations" label="Список связанных операций" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.license"
              label="Получение лицензии на осуществление деятельности"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.exportFinancing"
              label="Получение экспортного финансирования и страхования сделок"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.copyrightPermissions"
              label="Получение разрешений правообладателей на ввоз сырья и оборудования"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.followingTtrImport"
              label="Соблюдение мер таможенно-тарифного регулирования при ввозе сырья и оборудования"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.followingNtrImport"
              label="Соблюдение мер нетарифного регулирования при ввозе сырья и оборудования"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.exportControlOnImport"
              label="Соблюдение мер экспортного контроля при ввозе сырья и оборудования"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.confirmationAct"
              label="Получение заключения о подтверждении производства на территории РФ"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.externalMarketCertification"
              label="Сертификация для внешних рынков"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.intellectualPropertyProtection"
              label="Защита интеллектуальной собственности"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.stateRegistration"
              label="Государственная регистрация продукции"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.currencyControl"
              label="Выполнение требований валютного контроля"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.contractRegistration"
              label="Постановка контракта на учёт"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.followingTtr"
              label="Соблюдение мер таможенно-тарифного регулирования"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.ntrWaste"
              label="Соблюдение мер нетарифного регулирования(опасные отходы)"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.ntrDrugs"
              label="Соблюдение мер нетарифного регулирования(наркотики)"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.ntrMetals"
              label="Соблюдение мер нетарифного регулирования(драгоценные металлы)"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.ntrOzon"
              label="Соблюдение мер нетарифного регулирования(озоноразрущающие вещества)"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.ntrWildAnimals"
              label="Соблюдение мер нетарифного регулирования(дикие животные)"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.ntrPlants"
              label="Соблюдение мер нетарифного регулирования(дикорастущие растения)"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.exportControl"
              label="Соблюдение мер экспортного контроля"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.quarantinePhytosanitary"
              label="Соблюдение карантинных фитосанитарных требований"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.veterinary"
              label="Соблюдение ветеринарных требований"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.sourceCountry"
              label="Подтверждение страны происхождения товара"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.exportCompliance"
              label="Соблюдение требований к вывозу отдельных видов товаров"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.transportRegistration"
              label="Государственная регистрация для транспортировки по территории РФ"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.customsDeclaration" label="Таможенное декларирование" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.sanitarySupervision"
              label="Санитарно-эпидемиологический надзор на границе"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput v-model="card.exportPermission" label="Разрешение на вывоз" placeholder="Введите" />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.heavyTransportFee"
              label="Внесение платы за проезд тяжеловесного транспорта"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.railwayTransport"
              label="Оформление заявки на перевозку ж/д транспортом"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.checkpointPermission"
              label="Получение разрешения на въезд на территорию пункта пропуска"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.transportationThirdCountries"
              label="Получение разрешения на перевозку в третьи страны"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.heavyTransportPermission"
              label="Получение разрешения на движение тяжеловесных и(или) крупногабаритных транспортных средств"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.dangerGoodsTransportation"
              label="Получение разрешения на перевозку опасных грузов"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.heavyVehicleFee"
              label="Оплата проезда транспорта с массой свыше 12 тонн"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.controlPassing"
              label="Прохождение пограничного, таможенного, транспортного, карантинного фитосанитарного, санитарно-карантинного, ветеринарного контроля"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.customsProcedureDocuments"
              label="Получение документов для завершения таможенной процедуры"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.exportControlRequirements"
              label="Выполнение требований экспортного контроля по учету сделок"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.currencyControlRequirements"
              label="Выполнение требований валютного контроля"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.zeroVatConfirmation"
              label="Подтверждение права на применение налоговой ставки 0% НДС"
              placeholder="Введите"
            />
          </div>
        </div>
        <div class="row">
          <div class="form-group">
            <ComponentInput
              v-model="card.exportSupport"
              label="Получение мер финансовой и нефинансовой поддержки экспорта"
              placeholder="Введите"
            />
          </div>
        </div>
      </div>
      <div class="row mt-10">
        <div class="checkboxInput">
          <input type="checkbox" v-model="card.isActive" id="chActive" />
          <label for="chActive">Запись активна</label>
        </div>
      </div>
    </div>
    <div class="mb-16">
      <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
      <ButtonStock btn-type="secondary" title="Отмена" v-on:click="close" />
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import Constants from '@/common/constants';

  import ComponentInput from '../../../common/components/ComponentInput';
  import ButtonStock from '../../../components/buttons/ButtonStock';
  import API from '../api/procedure';
  export default {
    name: 'ProcedureCard',
    components: { ButtonStock, ComponentInput },
    data() {
      return {
        id: this.$route.params.id,
        card: {
          isTemplate: true,
        },
        openAdditional: false,
      };
    },
    created() {
      this.loadCard();
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.card = response.data;
          });
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }
        API.update(this.card)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && !this.card.id) {
              this.$router.push({ name: 'Procedures', params: { id: response.data.id, action: 'edit' } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Procedures' });
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .filter-trigger {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 16px;
    cursor: pointer;
    &:after {
      content: ' ';
      display: block;
      width: 8px;
      height: 8px;
      border-left: 2.5px solid black;
      border-bottom: 2.5px solid black;
      transform: rotate(315deg);
      transition: 0.3s;
      margin-left: 8px;
    }
    &.active {
      &:after {
        transform: rotate(135deg);
      }
    }
  }
</style>
